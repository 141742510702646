<template>
  <div>
    <b-card>
      <app-collapse
        accordion
        type="margin"
        class="mt-2"
      >

        <app-collapse-item
          v-for="( data,index) in items"
          :key="index"
          :title="data.numero_versao"
        >
          <div v-if="userId === '4'">
            <feather-icon
              icon="ToolIcon"
              class="mb-2 cursor-pointer"
              size="20"
              @click="modalEdit(data.id)"
            />
            <feather-icon
              icon="TrashIcon"
              class="ml-3 mb-2 cursor-pointer"
              size="20"
              @click="deletarVersao(data.id)"
            />
          </div>

          <div v-html="data.descricao" />
        </app-collapse-item>

      </app-collapse>
    </b-card>
    <b-modal
      ref="my-modal"
      hide-footer
      size="lg"
      title="Editar Versão"
    >
      <b-row>

        <b-col>
          <label for=""><strong>Versão: </strong></label>
          <b-form-input
            v-model="form.numero_versao"
            type="text"
          />
        </b-col>

      </b-row>
      <b-row class="mt-1">
        <b-col md="12">
          <label for=""><strong>Descrição: </strong></label>

          <quill-editor
            v-model="form.descricao"
          />

        </b-col>
        <b-row>
          <b-col>
            <b-button
              class="mt-2"
              type="submit"
              variant="danger"
              @click="hideModal"
            >
              Cancelar
            </b-button>
            <b-button
              class="mt-2 ml-3"
              type="submit"
              variant="primary"
              @click="editarVersao(idVersao)"
            >
              Atualizar
            </b-button>
          </b-col>
        </b-row>
      </b-row></b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BFormInput,
} from 'bootstrap-vue'
// imports do text-area customizado
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BCard,
    AppCollapseItem,
    AppCollapse,
    BButton,
    quillEditor,
    BFormInput,
  },
  data() {
    return {
      items: [],
      idVersao: null,
      form: {
        numero_versao: '',
        descricao: '',
      },
      userId: localStorage.getItem('userId'),
    }
  },
  created() {
    this.getVersoes()
  },
  methods: {
    getVersoes() {
      axios.get('api/v1/versao', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(item => {
          this.items.push({
            id: item.id,
            numero_versao: item.numero_versao,
            descricao: item.descricao,
          })
        })
      })
    },
    modalEdit(id) {
      axios.get(`api/v1/versao/edit/${id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.form = {
          numero_versao: res.data.dados.numero_versao,
          descricao: res.data.dados.descricao,
        }
      })
      this.idVersao = id
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.form = {
        numero_versao: '',
        descricao: '',
      }
      this.$refs['my-modal'].hide()
    },
    editarVersao(id) {
      axios.put(`api/v1/versao/update/${id}`, this.form, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(() => {
        this.hideModal()
        this.updateTabs()
        this.$swal({
          title: 'Versão Atualizada',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
      })
        .catch(() => {
          this.hideModal()
          this.updateTabs()
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: 'Ocorreu um erro durante a atualização!',
            customClass: {
              confirmButtonVariant: 'btn btn-primary',
            },
          })
        })
    },
    updateTabs() {
      this.items = []
      axios.get('api/v1/versao', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(item => {
          this.items.push({
            id: item.id,
            numero_versao: item.numero_versao,
            descricao: item.descricao,
          })
        })
      })
    },
    deletarVersao(id) {
      this.$swal({
        title: '',
        text: 'Deseja remover essa versão?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/versao/delete/${id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notificação',
                icon: 'BellIcon',
                text: 'Versão deletada com sucesso',
                variant: 'success',
              },
            }, {
              position: 'top-center',
              timeout: 1500,
            })
            this.$nextTick(() => {
              this.updateTabs()
            })
          }).catch(error => {
            this.$swal({
              title: 'Error! Algo inesperado aconteceu',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
